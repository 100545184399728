body {
    background-color:white;
}

.pageBody {
    position:relative;
    padding-top: 70px;
}

#title {
    text-align: center;
    font-family: arial, sans-serif;
}

#pageheaderbar {
    background-color: #e6decf;
    height: 45px;
    border: 1px solid hotpink;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#zonelist {
    font-size: 12px;
}

#buttoncol {
    text-align: right;
}

.btnMedium {
    width: 100px;
    height: 30px;
    font-size: 10pt;
    padding:0px;
    margin:0px;
}

.confirmDelete {
    max-width: 300px;
    left: 0vw;
}

.rdt_TableHeadRow {
    color: #f5da0f;
    background-color: #0b2d52;
}

.uploadErrorText {
    color: firebrick;
}

.uploadSuccessText {
    padding-top: 5px;
    padding-left:5px;
    color: forestgreen;
    font-size: 14px;
    font-weight: bold;
}

#reApplySuccessText {
    padding-top: 5px;
    padding-left: 5px;
    color: forestgreen;
    font-size: 14px;
    font-weight: bold;
}



.envTagAppSettings {
    font-size: 10px;
    color: ghostwhite;
    width: 99.5%;
    background-color: lightgrey;
    position: relative;
    padding-left: 5px;
    top: 0px;
    left: 2px;
}

.dotWrapper {
    width:99%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Buttons */
.btnStandard {
    font-size: 12px;
    width: 100px;
    color: white;
    background-color: #0D6EFD;
    border-radius: 4px;
    padding: 4px 10px;
    margin-bottom: 0;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
}

    .btnStandard:focus,
    .btnStandard:active:focus {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    .btnStandard:hover,
    .btnStandard:focus {
        color: white;
        text-decoration: none;
    }

    .btnStandard:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }


/* override to color the delete button */
.btnStandardDelete {
    background-color: #DC3545;
}
/* override to color the add button */
.btnStandardAddNew {
    background-color: #36D86C;
}
/* override to color the update button */
.btnStandardUpdate {
    background-color: #0D6EFD;
}
/* override to color the update button */
.btnStandardLowFocus {
    background-color: darkslategrey;
}
/* override to color the disabled button */
.btnStandardDisabled {
    background-color: darkgray;
    cursor: default;
}

.btnWide {
    width:200px;
    font-size:14px;
}

.btnLargeFont {
    font-size: 14px;
}



/* React Data Table CSS
.rdt_TableCell
.rdt_Table
.rdt_TableRow
.rdt_TableCol
.rdt_TableCol_Sortable
.rdt_TableCell
.rdt_TableHeader
.rdt_TableFooter
.rdt_TableHead
.rdt_TableHeadRow
.rdt_TableBody
.rdt_ExpanderRow
    */

