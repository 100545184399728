#activityTitle {
    font-size: 24px;
    font-weight: 600;
}

#activityDate {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: dimgrey;
    padding-left:5px;
}
#activityIDS {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: dimgrey;
    padding-left:5px;
}
#activityMainDataVal {
    font-size: 20px;
    color: black;
}

#activityMainDataLabel {
    font-size: 11px;
    color: dimgrey;
}

.activityNavArrow {
    opacity: 0.9;
    padding: 8px;
}
.activityNavArrow:hover {
    opacity: 1;
    background-color: hotpink;
    padding: 8px;
    border-radius:50%;
}

#activityNotesBox {
    color: darkslategrey;
    /* The white-space: pre-line ensures that \n chars will be properly rendered as breaks */
    white-space: pre-line;
    overflow-y: auto;
}
