#datatable {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 2px solid grey;
    width: 100%;
}
    #datatable th {
        border: 1px solid #ddd;
        border: 1px solid grey;
        padding: 5px;
        font-size: 13px;
    }
    #datatable td {
        border: 1px solid #ddd;
        padding: 5px;
        font-size: 13px;
        vertical-align:top;
    }
    #datatable tr:nth-child(even) {
        background-color: #f2f2f2;
    }
    #datatable tr:hover {
        background-color: #ddd;
    }

.cellW210{
    width:210px;
}
.cellW110 {
    width: 110px;
}
.cellW100 {
    width: 100px;
}
.cellW60 {
    width: 60px;
}
.cellW50 {
    width: 50px;
    text-align:right;
}
.DataTableRowLink {
    color: #268bd2;
}
    .DataTableRowLink:link {
        color: #268bd2;
    }
    .DataTableRowLink:hover {
        color: #f0e384;
    }

.DataTableExpandedRowLink {
    color: #c0c6cc;
}
    .DataTableExpandedRowLink:link {
        color: #c0c6cc;
    }
    .DataTableExpandedRowLink:hover {
        color: #f0e384;
    }

.updateConfirmationHidden{
    color:forestgreen;
    display:none;
    overflow:hidden;
}
.updateConfirmationVisible {
    color: forestgreen;
    display: block;
    font-weight: bold;
    overflow: hidden;
}

.inputZoneTitleValue {
    width: 200px;
    text-align: right;
}
.dateSelector {
    width: 100px;
    text-align: right;
    z-index:100;
}
.inputPaceValue {
    width: 100px;
    text-align: right;
}
.errorTextWrongPaceFormat{
    color:red;
}
#overlayPane {
    position: absolute;
    top: 0;
    left: 0;
    /*margin-top:-100px;*/
    width: 100%;
    height: 100%;
    background: rgba(250,250,255,.6);
    z-index: 10;
    display: block;
}

#processingDialogModalWindow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 50%;
    max-height: 200px;
    max-width: 400px;
    z-index: 22;
    display: table;
    background-color: white;
    opacity: 1;
    border: 3px solid grey;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#processingDialogInnerText {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 90%;
    height: 70%;
    transform: translate(-50%, -50%);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 3px;
    font-weight: 600;
}

#processingDialogProgressText {
    font-weight: normal;
    color:deepskyblue;
}

#progressBarPositioner {
    position: absolute;
    top: 99%;
    left: 50%;
    transform: translate(-50%, -15%);
    display: flex;
    justify-content: center;
    align-items: center;
}

#progressBarOuter {

    background-color: dimgrey;
    height: 10px;
    width: 100%;
    border-radius: 15px;
}
#progressBarInner {
    background-color: deepskyblue;
    height: 10px;
    width: 0%;
    border-radius: 15px;
}

#spinnerDiv {
    display:none;
    position: absolute;
    top: 99%;
    left: 50%;
    transform: translate(-50%, -15%);
    display: flex;
    justify-content: center;
    align-items: center;
}

