#summaryActivityCount {
 font-weight:bold;
}
#summaryTotalKms {
    font-weight:bold;
   }

#noActivitiesThisWeek {
    font-weight:bold;
   }
