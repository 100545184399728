.mainNavBar {
    background-color: cadetblue;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 2px;
    z-index:1;
}

.mainNavBarDevEnvironment {
    background-color: seagreen;
}

.mainNavBarStagEnvironment {
    background-color: #f5c131;
}

.envTag {
    font-size: 11px;
    color: ghostwhite;
    width: 75px;
    height: 16px;
    background-color: firebrick;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2;
    padding-left:5px;
    padding-bottom:2px;
    transform-origin: left;
    transform: translate(8px, -8px) rotate(90deg);
    border-radius:2px;
}


#navbrand {
    padding-top: 5px;
    padding-left: 5px;
}
.navBrandImage {
    opacity: 0.9;
    width: 50px;
    padding: 8px;
}

    .navBrandImage:hover {
        opacity: 1;
        background-color: white;
        border-radius: 50%;
    }

#navtitle {
    position:absolute;
    left:15%;
    top:10px;
    
    padding-top: 10px;
    padding-right: 20px;
    margin-left:-15px;
    text-align:left;
    white-space: nowrap;
    /*font-family: 'Fira code';*/
    color: aliceblue;
    font-size: 24px;
}

#navcollapse {
    width: 18%;
    white-space: nowrap;
    padding-right: 20px;
    padding-bottom: 0px;
    text-align: right;
    border-radius: 15px;
}

.navText {
    padding: 0px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navTextUser {
    color:silver;
    padding-top: 5px;
    font-size: 15px;
    font-weight: bold;
}

.navLinkItem {
    color: white;
    font-size: 17px;
    padding-left:10px;
}
    .navLinkItem:hover {
        color: lightyellow;
        text-decoration: underline;
    }

.navLinkBox {
    padding-top: 8px;
    padding-right: 0px;
    padding-left: 0px;
}
.navLinkImage {
    opacity: 0.9;
    border-radius: 45%;
    padding: 8px;
    width: 40px;
    height: 40px;    
}

    .navLinkImage:hover {
        opacity: 1;
        background-color: hotpink;
        padding: 8px;
    }

.downloadConnectIcon {
    width:25px;
    height: 25px;
}

.avatarBoxSmallscreen {
    position: absolute;
    right: 70px;
    top: 15px;
    text-align: right;
}
.navAvatarImage {
    width: 45px;
    height: 45px;
    border: 2px solid white;
    border-radius: 25px;
}
.navAvatarImage30px {
    width: 30px;
    height: 30px;
    border-radius: 50px;
}

/* The 'anchor' for the tooltip box*/
.navProfileContainer {
    position: relative;
    display: inline-block;
    padding: 8px;
}

    /* The tooltip box*/
    .navProfileContainer .navUserTooltip {
        visibility: hidden;
        width: 150px;
        background-color: hotpink;
        font-weight: bold;
        font-size: 15px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: 110%;
        left: 50%;
        margin-left: -110px;
    /* Add transition properties to delay the hiding of the box*/
        transition-property: visibility;
        transition-duration: 0.2s;
        transition-delay: 0.2s;
    }
        /* The arrow on the top of the tooltip box*/
        .navProfileContainer .navUserTooltip::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 74%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent hotpink transparent;
        }

.navProfileContainer:hover .navUserTooltip {
    visibility: visible;
    /* Reset the delay on hover */
        transition-delay: 0s;
}

