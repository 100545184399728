#overlayPane {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(250,250,255,.6);
    z-index: 10;
    display: block;
}

#processingDialogModalWindow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 70%;
    max-height: 250px;
    max-width: 400px;
    z-index: 22;
    display: table;
    background-color: white;
    opacity: 1;
    border: 3px solid grey;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#fitGetterProgress {
    font-size:14px;
    color:hotpink;

}

#processingDialogInnerText {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 90%;
    height: 70%;
    transform: translate(-50%, -50%);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 3px;
    font-weight: 600;
}

#processingDialogProgressText {
    visibility: hidden;
    font-weight: normal;
    color: deepskyblue;
}
#progressBarOuter {
    visibility: hidden;
    background-color: dimgrey;
    height: 10px;
    width: 100%;
    border-radius: 15px;
}
#progressBarInner {
    background-color: hotpink;
    height: 10px;
    width: 0%;
    border-radius: 15px;
}

.buttonContainer {
    padding-top:10px;
    text-align:left;
}

.buttonBox{
    float:left;
    margin:3px;
}
.appSettingsReactiveButton {
    border-radius: 5px;
    background-color: darkslategrey;
}


#spinnerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

#garminsectiontitle {
    font-size:24px;
    font-weight:500;
}
#garminLogo{
    border: 1px solid white;
    border-radius:7px;
}
#labelRefreshAllFits{
    cursor: pointer;
}
.cautionTextWhite {
    color: aliceblue;
    font-size: 13px;
    font-style: italic;
}

.cautionTextRed {
    color: hotpink;
    font-size: 13px;
    font-style: italic;
}

.bgLight {
    background-color: #291829;
    color: white;
}

#garminheaderbar {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

#garminlogobox {
    width:100%;
    text-align:right;
}

.garminInputField {
    margin-bottom: 10px;
}

.resGarminCredTestWrong {
    color: lightcoral;
    font-weight:500;
    margin-bottom:10px;
}
.resGarminCredTestCorrect {
    color: lightgreen;
    font-weight: 500;
    margin-bottom: 10px;
}
#errorTextInvalidGarminCreds{
    padding-top:20px;
    color: red;
}
#modalInvalidGarminCredsCloseWrapper {
    width:100%;
    text-align:right;
}
#modalInvalidGarminCredsCloseButton {
    background-color: grey;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid darkslategrey;
    border-radius: 2px;
    cursor: pointer;
    margin-right: -15px;
    margin-top: -10px;
}

.avatarBox {
    text-align: center;
    width: 120px;
}

    .avatarBox .bigAvatarImage {
        width: 100px;
        height: 100px;
        border: 2px solid white;
        border-radius: 50px;
    }

    .avatarBox .avatarChangeLink {
        text-decoration: none;
        color: aliceblue;
        font-size: 18px;
        font-size: 14px;
    }

        .avatarBox .avatarChangeLink:hover {
            text-decoration: underline;
            color: hotpink;
        }

.avatarHeader {
    font-size: 18px;
    padding-bottom:5px;
}

