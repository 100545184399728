.calendarHorizontalScrollMenu {
    width: 100%;
    height: 50px;
    border: 0px dotted hotpink;
    overflow: auto;
    white-space: nowrap;
    padding-left: 2px;
    padding-top: 8px;
}
    .calendarHorizontalScrollMenu::-webkit-scrollbar {
        height: 7px; /* height of horizontal scrollbar  */
        width: 0px; /* width of vertical scrollbar */
        background-color: #e3d6d5;
        border: 1px solid grey;
        border-radius: 3px;
    }

    .calendarHorizontalScrollMenu::-webkit-scrollbar-thumb:horizontal {
        background-color: grey;
        border-radius: 3px;
    }

.calendarHorizontalScrollMenuItem {
    background-color: lightgray;
    cursor: pointer;
    padding: 3px;
    padding-left:5px;
    border-radius: 15px;
    padding-bottom: 5px;
}

.calendarHorizontalScrollMenuItemSelected {
    background-color: hotpink;
    font-weight: bold;
    cursor:default;
}

#activityCalendarTable {
    border: 2px solid hotpink;
    font-size: 11px;
}
    #activityCalendarTable th {
        text-align: center;
        border: 2px solid hotpink;
        font-size: 14px;
        background-color: #f7f2f7;
        border-bottom: 2px solid hotpink;
    }

    #activityCalendarTable .totalsHeader {
        background-color: hotpink;
    }

    #activityCalendarTable td {
        height: 200px;
        width: 250px;
        border: 1px dotted hotpink;
        font-size: 12px;
        text-align: left;
        vertical-align: top;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    #activityCalendarTable tr td.totalsCell {
        background-color: #fcf2fb;
        border-left: 2px solid hotpink;
        padding-left:3px;
    }

        #activityCalendarTable td .totalsWeeknumBox {
            position: absolute;
            bottom: 2px;
            left: 2px;
            padding-bottom: 2px;
            padding-left:3px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        #activityCalendarTable td .totalsLabel {
            border-bottom: 1px dotted grey;
            min-height: 30px;
        }
        #activityCalendarTable td .totalsBox {
            padding-left: 3px;
            padding-right: 2px;
            min-width: 80px;
        }
        #activityCalendarTable td .totalsBoxClickable {
            cursor: pointer;
        }
        #activityCalendarTable td .totalKmsValue {
            font-size: 15px;
            font-weight: bold;
        }
        #activityCalendarTable td .totalsShareEasy {
            padding-top: 4px;
            padding-bottom: 1px;
        }
        #activityCalendarTable td .totalsShareModerate {
            padding-top: 2px;
            padding-bottom: 1px;
        }
        #activityCalendarTable td .totalsShareHard {
            padding-top: 2px;
            padding-bottom: 1px;
        }

        #activityCalendarTable td .shareEasyValue {
            background-color: lightgreen;
            border-radius: 5px;
            padding: 1px;
        }
        #activityCalendarTable td .shareModerateValue {
            background-color: orange;
            border-radius: 5px;
            padding: 1px;
        }
        #activityCalendarTable td .shareHardValue {
            background-color: lightcoral;
            border-radius: 5px;
            padding: 1px;
        }

        #activityCalendarTable td .daylabel {
            position: absolute;
            left: 5px;
            top: 5px;
            font-weight: bold;
            font-size: 10px;
            background-color: deeppink;
            text-align: center;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            padding: 2px;
            border-radius: 10px;
            color: white;
        }
        #activityCalendarTable td .datelabel {
            position: absolute;
            left: 30px;
            top: 5px;
            font-size: 10px;
            vertical-align: middle;
            padding-top: 2px;
            color: slategrey;
        }

        #activityCalendarTable td .activityCalendarActSummary {
            position: absolute;
            cursor: pointer;
            top: 30px;
            left: 5px;
            padding: 2px;
            min-width: 85%;
            max-width: 93%;
            min-height: 20%;
            max-height: 95%;
            background-color: lightblue;
            border-radius: 3px;
            font-weight: bold;
            color: darkslategray;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    #activityCalendarTable td a {
        font-size: 11px;
        font-weight: normal;
        color: darkslategrey;
        text-decoration: none;
    }
        #activityCalendarTable td a:hover {
            color: hotpink;
            text-decoration: underline;
        }
    #activityCalendarTable td .activityCalendarActSummaryRace {
        background-color: orange;
    }
        #activityCalendarTable td .activityCalendarActSummaryExtendedDetailsBox {
            font-weight:normal;
            padding-top:8px;
            font-size:10px;
            width: 100%;
        }
        #activityCalendarTable td .activityCalendarActSummaryExtendedDetailsItemContainer {

        }
        #activityCalendarTable td .activityCalendarActSummaryExtendedDetailsLabel {
            font-size:9px;
        }
        #activityCalendarTable td .activityCalendarActSummaryExtendedDetailsValue {
            font-weight:600;
        }

    #activityCalendarTable td .activityCalendarExtraActivitiesBox {
        position: absolute;
        z-index: 5;
        bottom: 2px;
        left: 5px;
        padding: 2px;
        min-width: 93%;
        max-width: 93%;
        min-height: 20%;
        max-height: 50%;
        background-color: lightgreen;
        border-radius: 3px;
        color: darkslategray;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
    }

        #activityCalendarTable td .activityCalendarExtraActivitiesBox .activityCalendarExtraActivitiesBoxHeader {
            font-size:12px;
        }

    #activityCalendarTable td .activityCalendarExtraActivitiesBoxDaytotalLabel {
        font-size: 10px;
    }

    #activityCalendarTable td .activityCalendarExtraActivitiesBoxDaytotalValue{
        font-size: 10px;
    }

        .containerNoData {
            height: 800px;
            text-align: center;
            vertical-align: top;
        }


#activityNotesBox {
    color: darkslategrey;
    /* The white-space: pre-line ensures that \n chars will be properly rendered as breaks */
    white-space: pre-line;
    overflow-y: auto;
}
